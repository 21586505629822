<template>
	<div class="pui-login--white-background" :class="{ 'pui-login--vertical-centered': !isMobile }" id="puilogin" @keyup.enter="doLogin">
		<!--Mobile case-->
		<v-card-text v-if="isMobile" class="mt-1 pt-2 pl-0 pr-0 pb-0 pui-login__form--mobile">
			<v-row>
				<v-col cols="4" class="login__logo"></v-col>
				<v-col cols="6" class="align_bottom">
					<span class="login-form_app-title">{{ $t('puilogin.apptitlepre') }}</span
					><br />
					<span class="login-form_app-title_sub">{{ $t('puilogin.apptitlesub') }}</span>
				</v-col>
			</v-row>

			<b>
				<h3 class="headline mb-2 pui-login__title pui-login__title--mobile">{{ $t('puilogin.title') }}</h3>
			</b>

			<v-form class="mt-4 pt-4">
				<div class="pui-login__input-label--mobile pl-3">{{ $t('puilogin.user') }}</div>
				<v-text-field
					autocomplete="new-user"
					name="login"
					type="text"
					v-model="user"
					:rules="nameRules"
					required
					class="pt-0 pui-login__text-field--mobile"
				></v-text-field>
				<div class="pui-login__input-label--mobile pl-3">{{ $t('puilogin.password') }}</div>
				<v-text-field
					name="password"
					id="password"
					class="pt-0 pui-login__text-field--mobile"
					v-model="password"
					:append-icon="hidePass ? 'far fa-eye' : 'far fa-eye-slash'"
					@click:append="showHidePass()"
					:type="hidePass ? 'text' : 'password'"
					required
				></v-text-field>
			</v-form>
			<div v-show="loginError" class="pui-login__textError--mobile ml-3 mr-3 mt-1 mb-2">{{ loginErrorMessage }}</div>
		</v-card-text>
		<v-card-actions v-if="isMobile" class="pa-0 my-2">
			<v-checkbox
				color="primary"
				:label="$t('puilogin.mantainsessionopened')"
				class="pui-login__checkSession--big ml-3 mr-3"
				v-model="keepSessionAlive"
			></v-checkbox>
		</v-card-actions>
		<v-card-actions v-if="isMobile" class="pl-3 pr-3 pt-0 pb-0">
			<v-btn block color="secondary" depressed class="elevation-0" @click.native="doLogin" :loading="running">
				{{ $t('puilogin.startsession') }}
			</v-btn>
		</v-card-actions>
		<!--No Mobile case-->
		<v-card-text v-if="!isMobile" class="pl-15 pr-15 pb-1 login-form">
			<v-row>
				<v-col cols="4" class="login__logo"></v-col>
				<v-col cols="6" class="align_bottom">
					<span class="login-form_app-title">{{ $t('puilogin.apptitlepre') }}</span
					><br />
					<span class="login-form_app-title_sub">{{ $t('puilogin.apptitlesub') }}</span>
				</v-col>
			</v-row>

			<v-form class="mt-4 pt-4">
				<div class="pui-login__inputLabel--desktop login-form_text-field-label">{{ $t('puilogin.user') }}</div>
				<v-text-field
					solo
					flat
					outlined
					name="login"
					id="login"
					type="text"
					v-model="user"
					:rules="nameRules"
					required
					class="desktop"
					@keyup.enter="doLogin"
				></v-text-field>
				<div class="pui-login__inputLabel--desktop login-form_text-field-label">{{ $t('puilogin.password') }}</div>
				<v-text-field
					solo
					flat
					outlined
					name="password"
					id="password"
					v-model="password"
					:append-icon="hidePass ? 'far fa-eye' : 'far fa-eye-slash'"
					@keyup.enter.native="doLogin"
					@click:append="showHidePass()"
					:type="hidePass ? 'text' : 'password'"
					required
					class="desktop"
				></v-text-field>
			</v-form>
			<div class="pui-login__textError--desktop pb-1">
				<p v-show="loginError">{{ loginErrorMessage }}</p>
			</div>
		</v-card-text>

		<v-card-actions v-if="!isMobile" class="d-flex flex-column align-start pl-15 pr-15 pt-0 pb-0 login-form">
			<v-checkbox
				class="pt-5 pb-5 login-form_opened-session"
				id="keepSession"
				color="primary"
				:label="$t('puilogin.mantainsessionopened')"
				v-model="keepSessionAlive"
			></v-checkbox>
			<v-btn
				id="submitLogin"
				autofocus
				color="primary"
				depressed
				class="elevation-0 pl-15 pr-15 pt-5 pb-5 login-form_login-button"
				@keyup.enter.native="doLogin"
				ref="login"
				@click.native="doLogin"
				:loading="running"
				:class="{ 'pui-login--deactivate': isBtnActive }"
				>{{ $t('puilogin.startsession') }}</v-btn
			>
		</v-card-actions>
		<v-card-actions>
			<slot name="puiLoginEndActions"></slot>
		</v-card-actions>
		<v-card-actions>
			<slot name="puiLoginAppActions"></slot>
		</v-card-actions>
	</div>
</template>

<script>
export default {
	name: 'OperationsIntroLogin',
	data() {
		return {
			user: '',
			password: '',
			nameRules: [(v) => !!v || this.nameRequiredMessage],
			hidePass: false,
			keepSessionAlive: true,
			isMobile: false,
			persistent: false
		};
	},

	created() {
		this.isMobile = this.$store.state.global.isMobile;
		this.persistent = this.$store.state.login.persistent;

		window.localStorage.removeItem('pui9_lang');

		this.getIsLDAPActive();
	},

	computed: {
		isBtnActive() {
			if (this.user & (this.user.length > 0) && this.password && this.password.length > 0) {
				return true;
			}
			return false;
		},
		nameRequiredMessage() {
			return this.$t('puilogin.nameRequired');
		},
		loginError() {
			return this.$store.state.login.error;
		},
		loginErrorMessage() {
			return this.$store.state.login.errorMessage;
		},
		running() {
			return this.$store.state.login.running;
		}
	},

	methods: {
		showHidePass() {
			this.hidePass = !this.hidePass;
		},

		doLogin() {
			if (!this.running) {
				const params = {
					user: this.user,
					password: this.password,
					persistent: this.persistent,
					keepSessionAlive: this.keepSessionAlive,
					router: this.$router,
					route: this.$route
				};

				this.$store.dispatch('puiLoginDoLogin', params);
			}
		},
		getIsLDAPActive() {
			this.$puiRequests.getRequest('/operations/isExternalLoginEnabled', null, (response) => {
				this.$store.state.login.forgetPassword = !response.data || false;
				this.$store.state.global.isLdapActive = response.data || false;
			});
		}
	},

	watch: {
		keepSessionAlive(value) {
			if (!value) {
				// remove token
				const infoKey = this.$store.getters.getSession.keepSessionAliveInfoKey;
				window.localStorage.removeItem(infoKey);
			}
		}
	}
};
</script>
<style lang="postcss">
.login-form {
	font-family: Montserrat;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.18;
	letter-spacing: normal;
	text-align: left;
	font-weight: 700;
	&_app-title {
		float: end;
		width: 429px;
		height: 33px;
		margin: 0px 11px 40px 0;
		font-size: 30px;
		font-weight: 500;
		color: #014efa;
	}
	&_app-title_sub {
		width: 429px;
		height: 33px;
		margin: 0px 11px 40px 0;
		font-size: 35px;
		font-weight: bold;
		color: #014efa;
	}
	&_text-field-label {
		width: 320px;
		height: 14px;
		flex-grow: 0;
		margin: 0 0 10px;
		font-size: 14px;
		font-weight: bold;
		color: #000;
	}
	&_login-button {
		width: 200px;
		height: 36px;
		margin: 10px 240px 10px 0;
		font-size: 15px;
		font-weight: bold;
		text-align: center;
		color: #fff;
	}
	&_opened-session {
		font-size: 12px;
		font-weight: normal;
		color: #000;
	}
}
.align_bottom {
	align-self: flex-end;
	padding: 0;
}
</style>
